.noteslist {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.676) !important;
    padding: 5px !important;
    overflow: hidden;
    max-height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}

.noteslist.active,
.noteslist:focus {
    border-color: white !important;
    border-top: none;
    color: rgb(255, 255, 255) !important;
    font-weight: 800 !important;
}

