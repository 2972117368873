.home-container {
    padding: 2rem;
    padding-top: 1rem;
}
  
.text-center {
    text-align: center;
}



.feature h2 {
    margin-bottom: 1rem;
}

.features-section {
  min-height: 270px; 
}

.feature {
    height:95%;
    padding: 1rem;
    padding-inline: 2rem !important;
    background-color: rgba(0, 0, 0, 0.301);
    border-radius: 3px;
    border: 1px solid white;
}