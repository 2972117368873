*{
  box-sizing: border-box;
  font-family: 'Garamond','Serif';
}

body{
  padding: 0px;
  margin: 0px;
}

.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: white;
  background-color: rgb(10, 34, 39);
  font-size:large;
}

.campaign-title {
  border-bottom: 2px solid white;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;

}