#campaign-tabs {
    border: none;
}

#campaign-tabs .nav-item .nav-link {
    background-color: transparent;
    color: white;
    border: none;
    outline: none; 
    transition: none !important;
}

#campaign-tabs .nav-item .nav-link.active {
    background-color: rgb(10, 34, 39);
    border-bottom: 2px solid rgb(10, 34, 39) !important;
    margin-bottom: -2px;
    outline: none; 
}

#campaign-tabs .tab-content {
    background-color: #fff;
    border: none; 
}

.tabs-container {
    width: 100% !important;
    margin: 0px !important;
    background-color: rgba(0, 0, 0, 0.555);
}

.campaignView-container {
    width: 100%;
    padding: 0px !important;
}
